<template>
    <fullscreen-loader message="Connecting to Atlassian..." />
</template>

<script>
import {handleCallbackFromAtlassian} from '@/api/atlassian';

import FullscreenLoader from '@/components/generic/FullscreenLoader';
import {firebaseApp} from '@/utils/firebase';

export default {
    name: 'atlassian',
    components: {
        FullscreenLoader,
    },
    created() {
        // wait for user auth before calling xero auth
        firebaseApp.auth().onAuthStateChanged(() => {
            this.authenticate();
        });
    },
    methods: {
        async authenticate() {
            console.log(
                this.getQueryStringValue('code'),
                this.getQueryStringValue('state')
            );

            const result = await handleCallbackFromAtlassian(
                this.getQueryStringValue('code'),
                this.getQueryStringValue('state')
            );

            if (result.success) {
                this.navigateToHome();
            }
        },
        getQueryStringValue(key) {
            return decodeURIComponent(
                window.location.search.replace(
                    new RegExp(
                        '^(?:.*[&\\?]' +
                            encodeURIComponent(key).replace(
                                // eslint-disable-next-line no-useless-escape
                                /[\.\+\*]/g,
                                '\\$&'
                            ) +
                            '(?:\\=([^&]*))?)?.*$',
                        'i'
                    ),
                    '$1'
                )
            );
        },
        navigateToHome() {
            this.$router.push({name: 'home'}).catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
body {
    background: $black;
}
</style>
